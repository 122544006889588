import { mapActions, mapGetters } from 'vuex'
import { clone, differenceBy } from 'lodash'

import mixins from '@/mixins'
import course, { getComponentsItemBySlug } from '@/mixins/course'

import Draggable from 'vuedraggable'

import SelectPackages from '@/components/forms/SelectPackages';

export default {
  name: 'courses-components',
  mixin: [mixins, course],
  data () {
    return {
      valid: false,
      components: [],
      selectedComponents: [],
      popupPackages: false,
      selectedPackages: [],
      editedComponentPackages: ''
    }
  },
  components: {
    Draggable,
	SelectPackages
  },
  watch: {
    selectedComponents () {
      this.components = differenceBy(this.allComponents, this.selectedComponents, 'customId')
    }
  },
  computed: {
    allComponents () {
      if (this.setting) {
        return this.setting.courses.components
      } else {
        return []
      }
    },
    ...mapGetters({
      packagesList: 'coursesPackages/list',
      courseModules: 'coursesSections/courseModules',
      isListLoading: 'coursesSections/isListLoading',
      updatePackageLoading: 'coursesSections/updatePackageLoading',
      setting: 'courses/setting',
      isLoading: 'coursesSections/isLoading'
    })
  },
  created () {
    this.setCourseStep(3)

    if (!this.courseModules.length) {
      this.fetchList({ id: this.$route.params.course_id }).then(() => {
        this.setDefaultModules()
      })
    } else {
      this.setDefaultModules()
    }
  },
  methods: {
    ...mapActions({
      fetchList: 'coursesSections/GET_LIST',
      updatePackages: 'coursesSections/UPDATE_PACKAGES',
      updatePositions: 'coursesSections/UPDATE_POSITIONS',
      create: 'coursesSections/CREATE',
      delete: 'coursesSections/DELETE'
    }),

    updatePositionsLocal () {
      const copyArray = clone(this.selectedComponents)
      const payload = {
        course_id: this.$route.params.course_id,
        components: copyArray.map(e => e.id)
      }
      return this.updatePositions(payload).then(() => {
        this.setDefaultModules()
      })
    },
    changeSelectedModules (e) {
      if (e.hasOwnProperty('added')) {
        const formData = {
          course_id: this.$route.params.course_id,
          component_id: e.added.element.id,
          sort: e.added.newIndex
        }
        this.create(formData).then(response => {
          this.selectedComponents.splice(e.added.newIndex, 1, getComponentsItemBySlug(response))
          this.updatePositionsLocal().then(() => {
            this.$toasted.success(this.$t('course_add_components'))
          })
        })
      }
      if (e.hasOwnProperty('removed')) {
        const formData = {
          course_id: this.$route.params.course_id,
          id: e.removed.element.id
        }
        this.delete(formData).then(() => {
          if (this.selectedComponents.length > 0) {
            this.updatePositionsLocal().then(() => {
              this.$toasted.success(this.$t('course_remove_components'))
            })
          } else {
            this.setDefaultModules()
          }
        })
      }
      if (e.hasOwnProperty('moved')) {
        this.updatePositionsLocal().then(() => {
          this.$toasted.success(this.$t('success_change_position'))
        })
      }
    },
    setDefaultModules () {
      this.components = differenceBy(this.allComponents, this.courseModules, 'customId')
      this.selectedComponents = this.courseModules
    },
    back () {
      this.goToStep('courses.packages', 2)
    },
    openPopUpPackages (item = null) {
      if (item) {
        this.popupPackages = true
        this.editedComponentPackages = item
        this.selectedPackages = item.packages.data.map(e => e.id)
      } else {
        this.popupPackages = false
        this.editedComponentPackages = ''
        this.selectedPackages = []
      }
    },
    updatePackagesLocal () {
      const formData = {
        packages: this.selectedPackages,
        course_id: this.$route.params.course_id,
        id: this.editedComponentPackages.id,
        sort: this.editedComponentPackages.sort
      }
      this.updatePackages(formData).then(() => {
        this.$toasted.success(this.$t('modulesAccessSuccessUpdate'))
        this.popupPackages = false
        this.editedComponentPackages = ''
        this.selectedPackages = []
        this.setDefaultModules()
      })
    },
    moveAllComponents () {
      const clone = this.components.map(e => e)
      const arrRequests = clone.map((e, index = this.selectedComponents.length) => {
        const formData = {
          course_id: this.$route.params.course_id,
          component_id: e.id,
          sort: index
        }
        return this.create(formData).then(response => {
          const index = this.selectedComponents.findIndex(e => e.id === response.id)
          this.selectedComponents.splice(index, 1)
          this.$toasted.success(this.$t('course_add_components'))
        })
      })
      Promise.all([arrRequests]).finally(() => {
        this.updatePositionsLocal()
      })
      this.selectedComponents.push(...this.components)
    },
    next () {
      this.$refs.form.validate()
      if (this.valid) {
        this.goToStep('courses.appearance', 4)
      }
    }
  }
}
